import PropTypes from 'prop-types'
import { APPLY_FDR_URL } from 'src/common/constants'
import { useCallback, useMemo } from 'react'
import { useAnalytics } from '../../../common/analytics'
import { useLeadId } from '../../../common/lead-id'
import { useRouter } from 'next/router'
import { buildQueryString } from '../../../common/utils'
import { generateElementId } from '../../../common/helpers'
import { useNavigateToApply } from 'src/common/hooks/useNavigateToApply'

const CallToAction = ({
  className,
  title,
  text,
  fontClassName,
  bgColor,
  endIcon = null,
  experimentData = {},
  additional_info = {},
  nav_link_section,
  fullWidth = false,
}) => {
  const { track } = useAnalytics()
  const leadId = useLeadId()
  const { query: baseQuery } = useRouter()
  const navigateToApply = useNavigateToApply()

  const query = useMemo(
    () => buildQueryString(baseQuery, leadId),
    [baseQuery, leadId]
  )
  const id = useMemo(() => generateElementId(text), [text])

  const url = `${APPLY_FDR_URL()}?${query}`

  const handleButtonClick = useCallback(
    (e) => {
      e.preventDefault()

      const data = {
        nav_link_section: nav_link_section,
        page_section: title,
        click_type: 'Button Click',
        click_id: text,
        click_url: url,
        click_text: `FDR-Web | ${text}`,
        track_event: 'button_click',
        additional_info,
      }
      track(data, { event_type: 'conversion' }, 'button_click')

      navigateToApply()
    },
    [
      nav_link_section,
      title,
      text,
      url,
      additional_info,
      track,
      navigateToApply,
    ]
  )

  return (
    <a
      target="_blank"
      href={url}
      rel="nofollow"
      className={`${
        fullWidth ? 'w-full' : 'w-64'
      } py-3 text-center ${className} ${fontClassName} ${bgColor} box-border`}
      onClick={handleButtonClick}
      id={`call-to-action-${id}`}
    >
      {text}
      {endIcon}
    </a>
  )
}

CallToAction.defaultProps = {
  className: '',
  fontClassName: 'text-sm font-normal leading-snug tracking-normal text-white',
  children: [],
  bgColor: 'bg-red-700',
  text: 'Get Your Free Debt Evaluation',
  pageLocation: '',
  title: '',
  endIcon: null,
  additional_info: undefined,
  nav_link_section: 'Page',
}

CallToAction.propTypes = {
  className: PropTypes.string,
  bgColor: PropTypes.string,
  fontClassName: PropTypes.string,
  text: PropTypes.string,
  pageLocation: PropTypes.string,
  title: PropTypes.string,
  endIcon: PropTypes.node,
  experimentData: PropTypes.object,
  additional_info: PropTypes.object,
}

export default CallToAction
